import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	backgroundRoot: {
		maxHeight: '100vh',
		zIndex: -1,
		display: 'block'
	},
	backgroundImg: {
		width: '80%',
		height: '100vh',
		objectFit: 'cover'
	}
}));

interface IBackgroundImageProps {
	// the webP image to be inserted
	image: string;
	// an alt descriptor of the img
	alt: string;
}
const BackgroundImage: React.FC<IBackgroundImageProps> = ({ image, alt }) => {
	const classes = useStyles();

	return (
		<picture className={classes.backgroundRoot}>
			<img src={image} alt={alt} className={classes.backgroundImg} />
		</picture>
	);
};

export default BackgroundImage;
