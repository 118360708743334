import React, { useState, Fragment } from 'react';

import { useGlobalState } from '../../contexts/GlobalStateProvider';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { Color } from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import AccountBoxIcon from '@material-ui/icons/AccountBox';

import { IStaff } from '../../interfaces/staff';
import { staffHeaderCells } from '../../utils/tableData';

import { makeStyles } from '@material-ui/core/styles';

import Form from '../../components/Form';
import Field from '../../components/Field';
import { staffFields } from '../../utils/inputFields';

import Confirmation from '../../components/Confirmation/Confirmation';
import SelectSortTable from '../../components/SelectSortTable';

import axios from 'axios';

interface IManageTeamProps {
	staff: IStaff[];

	onStaffChanged: (newStaffList: IStaff[]) => void;
}

const useStyles = makeStyles(theme => ({
	title: {
		marginTop: '45px'
	},
	staffDetailPaper: {
		marginTop: '25px',
		padding: '15px',
		width: '100%'
	},
	addStaffDetails: {
		marginTop: '25px'
	},
	gridContainer: {
		width: '70vw',
		padding: '1rem 0'
	}
}));

const ManageTeam: React.FC<IManageTeamProps> = ({ staff, onStaffChanged }) => {
	const classes = useStyles();
	const { state } = useGlobalState();
	const [showStaffDetails, setShowStaffDetails] = useState<boolean>(false);
	const [showConfirmLock, setShowConfirmLock] = useState<boolean>(false);
	const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
	const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
	const [snackbarSeverity, setSnackBackSeverity] = useState<Color | undefined>(undefined);
	const [snackbarMessage, setSnackbarMessage] = useState<string>('');
	const [selectedStaff, setSelectedStaff] = useState<number | undefined>(undefined);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/**
	 * Show the staff details for adding and editing
	 * @param {any} id - potential id of selected staff for editing
	 */
	const handleShowStaffDetails = (id: any): void => {
		const staffMember = staff.find(staff => staff.id === id);

		setIsEdit(staffMember !== undefined);

		if (staffMember) {
			setSelectedStaff(id);
		} else {
			setSelectedStaff(undefined);
		}

		// Set the field values if in edit mode
		staffFields.firstName.value = staffMember ? staffMember.firstName : '';
		staffFields.lastName.value = staffMember ? staffMember.lastName : '';
		staffFields.email.value = staffMember ? staffMember.email : '';
		staffFields.role.value = staffMember ? staffMember.role : '';

		setShowStaffDetails(true);
	};

	/**
	 * Makes request to add or modify staff to database (POST/PUT)
	 * @param {any} staffData - All the staff info needed to add or edit
	 */
	const handleAddStaff = (staffData: any): void => {
		setIsLoading(true);
		if (isEdit) {
			const staffMember = getSelectedStaff();
			if (staffMember) {
				const editedStaffMember: IStaff = {
					firstName: staffData.firstName,
					lastName: staffData.lastName,
					email: staffData.email,
					role: staffData.role,
					enabled: staffMember.enabled
				};

				axios
					.put(`/api/staff/${selectedStaff}`, JSON.stringify(editedStaffMember), {
						headers: { 'Content-Type': 'application/json' }
					})
					.then(response => {
						const newStaffList = [...staff];
						const newStaffMember: IStaff = response.data;
						const oldStaffMemberIndex = staff.findIndex(
							member => member.id === selectedStaff
						);
						newStaffList[oldStaffMemberIndex] = newStaffMember;
						onStaffChanged(newStaffList);
						setShowStaffDetails(false);
						setIsLoading(false);
						setSnackBackSeverity('success');
						openSnackbar('Account has been edited.');
					})
					.catch(err => {
						setSnackBackSeverity('error');
						const code = err.response.status;
						setIsLoading(false);
						if (code === 409) {
							openSnackbar(
								'Can not update email, an account with this email already exists.'
							);
						} else {
							openSnackbar('Failed to update account, please try again.');
						}
					});
			}
		} else {
			const newStaff: IStaff = {
				firstName: staffData.firstName,
				lastName: staffData.lastName,
				email: staffData.email,
				role: staffData.role
			};

			axios
				.post('/api/staff', JSON.stringify(newStaff), {
					headers: { 'Content-Type': 'application/json' }
				})
				.then(response => {
					const newStaffMember: IStaff = response.data;
					const newStaffList = [...staff, newStaffMember];
					onStaffChanged(newStaffList);
					setShowStaffDetails(false);
					setIsLoading(false);
					setSnackBackSeverity('success');
					openSnackbar('Account has been added.');
				})
				.catch(err => {
					setSnackBackSeverity('error');
					const code = err.response.status;
					setIsLoading(false);
					if (code === 409) {
						openSnackbar('Account with this email already exists.');
					} else {
						openSnackbar('Failed to add account, please try again.');
					}
				});
		}
	};

	/**
	 * When user selects staff from table
	 * @param {number | undefined} newSelectedStaff - The id of selected staff or undefined if no selected
	 */
	const handleSelectedStaff = (newSelectedStaff: number | undefined): void => {
		if (newSelectedStaff === undefined) {
			setShowStaffDetails(false);
		}
		setSelectedStaff(newSelectedStaff);
	};

	/**
	 * Get the staff member that is selected in table
	 * @returns {IStaff | undefined[]} - The selected staff or undefined if not found
	 */
	const getSelectedStaff = (): IStaff | undefined => {
		return staff.find(staff => staff.id === selectedStaff);
	};

	const isEnabled = getSelectedStaff()?.enabled === true;

	/**
	 * Make request to lock staff account (PUT)
	 */
	const handleLockStaff = (): void => {
		const staffMember = getSelectedStaff();

		if (staffMember) {
			const lockEditedStaffMember: IStaff = {
				firstName: staffMember.firstName,
				lastName: staffMember.lastName,
				email: staffMember.email,
				role: staffMember.role,
				enabled: false,
				requiresPasswordReset: staffMember.requiresPasswordReset
			};

			const unlockEditedStaffMember: IStaff = {
				firstName: staffMember.firstName,
				lastName: staffMember.lastName,
				email: staffMember.email,
				role: staffMember.role,
				enabled: true,
				requiresPasswordReset: staffMember.requiresPasswordReset
			};

			const editedStaffMember = staffMember.enabled
				? lockEditedStaffMember
				: unlockEditedStaffMember;

			axios
				.put(`/api/staff/${selectedStaff}`, JSON.stringify(editedStaffMember), {
					headers: { 'Content-Type': 'application/json' }
				})
				.then(response => {
					const newStaffList = [...staff];
					const newStaffMember: IStaff = response.data;
					const oldStaffMemberIndex = staff.findIndex(
						member => member.id === selectedStaff
					);
					newStaffList[oldStaffMemberIndex] = newStaffMember;
					onStaffChanged(newStaffList);
					setSnackBackSeverity('success');
					setShowConfirmLock(false);
					openSnackbar(`Account has been ${isEnabled ? 'locked' : 'unlocked'}`);
				})
				.catch(err => {
					setSnackBackSeverity('error');
					setShowConfirmLock(false);
					openSnackbar(
						`Failed to ${isEnabled ? 'lock' : 'unlock'} account, please try again.`
					);
				});
		}
	};

	/**
	 * Makes a request to delete staff from database (DELETE)
	 */
	const handleDeleteStaff = (): void => {
		axios
			.delete(`/api/staff/${selectedStaff}`)
			.then(response => {
				const newStaffList = staff.filter(member => member.id !== selectedStaff);
				onStaffChanged(newStaffList);
				setSelectedStaff(undefined);
				setShowConfirmDelete(false);
				setSnackBackSeverity('success');
				openSnackbar('Account Deleted.');
			})
			.catch(err => {
				setSnackBackSeverity('error');
				openSnackbar('Failed to delete account, please try again.');
			});
	};

	/**
	 * Show snackbar with confirmation message
	 * @param {string} message - The message to display on the snackbar
	 */
	const openSnackbar = (message: string) => {
		setSnackbarMessage(message);
		setShowSnackbar(true);
	};

	return (
		<Grid container justify="center" spacing={5}>
			<Grid item className={classes.title}>
				<Typography variant="h3">Manage Team</Typography>
				<hr />
			</Grid>
			<SelectSortTable
				size={10}
				headers={staffHeaderCells}
				data={staff}
				selected={selectedStaff}
				onSelectedChange={handleSelectedStaff}
				onDelete={() => setShowConfirmDelete(true)}
				onEdit={id => handleShowStaffDetails(id)}
				onLock={() => setShowConfirmLock(true)}
				removeCheckbox
			/>
			<Grid container justify="flex-start" className={classes.gridContainer}>
				<Grid item>
					<Button
						variant="contained"
						disableTouchRipple
						startIcon={<AccountBoxIcon />}
						onClick={() => handleShowStaffDetails(-1)}
					>
						Add Member
					</Button>
				</Grid>
				{showStaffDetails && (
					<Fragment>
						{!isLoading ? (
							<Grid container className={classes.gridContainer}>
								<Paper className={classes.staffDetailPaper}>
									<Grid container justify="flex-end">
										<Grid item>
											<Button
												color="primary"
												onClick={() => setShowStaffDetails(false)}
											>
												X
											</Button>
										</Grid>
									</Grid>
									<Grid
										container
										justify="center"
										className={classes.addStaffDetails}
									>
										<Grid item>
											<Typography variant="h6">
												Team Member Details
											</Typography>
											<hr />
										</Grid>
									</Grid>
									<Form
										onSubmit={handleAddStaff}
										fields={staffFields}
										render={() => (
											<Fragment>
												<Grid container justify="space-between">
													<Grid item xs={5}>
														<Field
															{...staffFields.firstName}
															value={
																isEdit
																	? getSelectedStaff()?.firstName
																	: ''
															}
														/>
													</Grid>
													<Grid item xs={5}>
														<Field
															{...staffFields.lastName}
															value={
																isEdit
																	? getSelectedStaff()?.lastName
																	: ''
															}
														/>
													</Grid>
												</Grid>
												<Grid container justify="space-between">
													<Grid item xs={5}>
														<Field
															{...staffFields.email}
															value={
																isEdit
																	? getSelectedStaff()?.email
																	: ''
															}
														/>
													</Grid>
													<Grid item xs={5}>
														<Field
															{...staffFields.role}
															value={
																isEdit
																	? getSelectedStaff()?.role
																	: ''
															}
															disabled={
																isEdit &&
																getSelectedStaff()?.id ===
																	state.user?.id
															}
														/>
													</Grid>
												</Grid>
												<Grid container justify="flex-end">
													<Grid item>
														<Button
															type="submit"
															variant="contained"
															disableTouchRipple
														>
															{isEdit ? 'Edit Staff' : 'Add Staff'}
														</Button>
													</Grid>
												</Grid>
											</Fragment>
										)}
									/>
								</Paper>
							</Grid>
						) : (
							<Grid
								container
								justify="center"
								alignItems="center"
								className={classes.gridContainer}
							>
								<Grid item>
									<CircularProgress size={100} color="primary" />
								</Grid>
							</Grid>
						)}
					</Fragment>
				)}
			</Grid>
			<Confirmation
				visiblity={showConfirmLock}
				title={`${isEnabled ? 'Lock' : 'Unlock'} this Account?`}
				content={`Are you sure you want to ${isEnabled ? 'lock' : 'unlock'} this account?`}
				onConfirm={handleLockStaff}
				onCancel={() => setShowConfirmLock(false)}
			/>
			<Confirmation
				visiblity={showConfirmDelete}
				title="Delete this Account?"
				content="Are you sure you want to delete this account?"
				onConfirm={handleDeleteStaff}
				onCancel={() => setShowConfirmDelete(false)}
			/>
			<Snackbar
				open={showSnackbar}
				autoHideDuration={3000}
				onClose={() => setShowSnackbar(false)}
			>
				<MuiAlert severity={snackbarSeverity}>{snackbarMessage}</MuiAlert>
			</Snackbar>
		</Grid>
	);
};

export default ManageTeam;
