import React, { Fragment } from 'react';
import { GlobalStateProvider } from './contexts/GlobalStateProvider';
import * as Sentry from '@sentry/react';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './theme';

import Routing from './Routing';
import BrowserErrorHandling from './components/ErrorHandling/BrowserErrorHandling';

const App: React.FC = () => {
	return (
		<Fragment>
			<ThemeProvider theme={theme}>
				<GlobalStateProvider>
					<Routing />
					{/* 
						Browser compatibility check-
						displays link to download modern browsers if not supported.
					*/}
					<BrowserErrorHandling />
				</GlobalStateProvider>
			</ThemeProvider>
		</Fragment>
	);
};

/**
 * Sentry.io & React Profiler: https://docs.sentry.io/platforms/javascript/guides/react/components/profiler/
 * generates spans w/ 3 kinds of op-codes: react.mount, react.render & react.update
 *
 * **/
export default Sentry.withProfiler(App);
