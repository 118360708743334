import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import './index.css';
import App from './App';

const versionInfo = require("./version.json");

Sentry.init({
	dsn: "https://2f6764fb8bfa451ab6eca2a80e85e898@o950424.ingest.sentry.io/5899015",
	environment: process.env.NODE_ENV,
	release: versionInfo.version,
	integrations: [new Integrations.BrowserTracing()],
	// modal that gives user the option to submit feedback and provide more details
	beforeSend(event) {
		if (event.exception) {
			Sentry.showReportDialog({ eventId: event.event_id });
		}
		return event;
	},
	tracesSampleRate: 1.0
});

ReactDOM.render(<App />, document.getElementById('root'));
