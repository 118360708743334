import React, { Fragment, useState, useEffect, useContext } from 'react';
import { IErrors, IFormContext, FormContext, IValues } from './Form';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import { DateTimePicker } from '@material-ui/pickers';

import { makeStyles } from '@material-ui/core/styles';

/* The available editors for the field */
type Editor = 'textbox' | 'multilinetextbox' | 'dropdown' | 'date';

export interface IValidation {
	/* The rule function to use for this validation*/
	rule: (values: IValues, fieldName: string, args: any) => string;

	/* Any arguments involved with this rule */
	args?: any;
}

export interface IFieldProps {
	id: string;

	/* The label text for the field */
	label?: string;

	/* The type of input for the field */
	type?: string;

	/* The editor for the field */
	editor?: Editor;

	/* The drop down items for the field */
	options?: string[];

	/* If the value in options does not need lowercase*/
	noLowerCaseOptionValue?: boolean;

	/* The field value */
	value: any;

	/* The necessary validation for this field*/
	validation?: IValidation[];

	/*** Input properties below ***/
	autoFocus?: boolean;

	fullWidth?: boolean;

	disabled?: boolean;
}

const useStyles = makeStyles(theme => ({
	inputField: {
		marginTop: '10px',
		marginBottom: '10px'
	},
	dropdown: {
		marginTop: '10px',
		marginBottom: '10px',
		width: '100%'
	}
}));

const Field: React.FC<IFieldProps> = ({
	id,
	label,
	type,
	autoFocus,
	fullWidth,
	disabled,
	editor,
	options,
	noLowerCaseOptionValue,
	value
}) => {
	const classes = useStyles();

	const [fieldValue, setFieldValue] = useState<any>(value);

	const formContext = useContext(FormContext);

	useEffect(() => {
		if (formContext) {
			formContext.values[id] = value;
		}
		setFieldValue(value);
		// eslint-disable-next-line
	}, [value, id]);

	/**
	 * Gets the validation error for the field
	 * @param {IErrors} errors - All the errors from the form
	 * @returns {string[]} - The validation error
	 */
	const getError = (errors: IErrors | undefined): string => {
		if (errors !== undefined) {
			if (errors[id]) {
				return errors[id][0];
			}
		}
		return '';
	};

	return (
		<FormContext.Consumer>
			{(context: IFormContext | undefined) => (
				<div>
					{editor!.toLowerCase() === 'textbox' && (
						<Fragment>
							{/* labels are hidden, but a screenreader can still pick them up. */}
							<InputLabel style={{ display: 'none' }} htmlFor={label}>
								{label}
							</InputLabel>
							<TextField
								autoFocus={autoFocus}
								fullWidth={fullWidth}
								type={type}
								variant="outlined"
								label={label}
								id={label}
								error={
									getError(context?.errors) !== '' &&
									getError(context?.errors) !== undefined
										? true
										: false
								}
								helperText={getError(context?.errors)}
								value={fieldValue}
								onChange={e => {
									context?.setValues({ [id]: e.currentTarget.value });
									setFieldValue(e.currentTarget.value);
								}}
								onBlur={e => context?.validate(id)}
								className={classes.inputField}
							/>
						</Fragment>
					)}
					{editor!.toLowerCase() === 'multilinetextbox' && (
						<Fragment>
							<InputLabel style={{ display: 'none' }}>{label}</InputLabel>
							<TextField
								multiline
								rows={4}
								autoFocus={autoFocus}
								fullWidth={fullWidth}
								type={type}
								variant="outlined"
								label={label}
								error={
									getError(context?.errors) !== '' &&
									getError(context?.errors) !== undefined
										? true
										: false
								}
								helperText={getError(context?.errors)}
								value={fieldValue}
								onChange={e => {
									context?.setValues({ [id]: e.currentTarget.value });
									setFieldValue(e.currentTarget.value);
								}}
								onBlur={e => context?.validate(id)}
								className={classes.inputField}
							/>
						</Fragment>
					)}
					{editor!.toLowerCase() === 'dropdown' && (
						<FormControl
							variant="outlined"
							error={
								getError(context?.errors) !== '' &&
								getError(context?.errors) !== undefined
									? true
									: false
							}
							className={classes.dropdown}
						>
							<InputLabel>{label}</InputLabel>
							<Select
								id={id}
								name={id}
								value={fieldValue}
								disabled={disabled ? disabled : false}
								onChange={e => {
									context?.setValues({ [id]: e.target.value });
									setFieldValue(e.target.value);
								}}
								onBlur={e => context?.validate(id)}
								label={label}
							>
								{options &&
									options.map(option => (
										<MenuItem
											key={option}
											value={
												noLowerCaseOptionValue
													? option
													: option.toLocaleLowerCase()
											}
										>
											{option}
										</MenuItem>
									))}
							</Select>
							<FormHelperText>{getError(context?.errors)}</FormHelperText>
						</FormControl>
					)}{' '}
					{editor!.toLowerCase() === 'date' && (
						<React.Fragment>
							<InputLabel>{label}</InputLabel>
							<DateTimePicker
								value={fieldValue}
								onChange={e => {
									context?.setValues({ [id]: e });
									setFieldValue(e);
								}}
								disablePast
								allowKeyboardControl={false}
							/>
							<FormHelperText style={{ color: 'red' }}>
								{getError(context?.errors)}
							</FormHelperText>
						</React.Fragment>
					)}
				</div>
			)}
		</FormContext.Consumer>
	);
};
Field.defaultProps = {
	editor: 'textbox'
};

export default Field;
