import { Fragment, useReducer, useEffect } from 'react';
import { DailyCall, DailyEvent, DailyTrackState } from '@daily-co/daily-js';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import {
	callReducer,
	FATAL_ERROR,
	initialCallState,
	isLocal,
	isScreenSharing,
	PARTICIPANTS_CHANGE
} from '../../utils/callState';
import VideoTile from './VideoTile';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		alignContent: 'center',
		justifyContent: 'center'
	},
	item: {
		height: 'auto',
		width: '100%',
		maxWidth: '65vw'
	}
}));

interface IScreenShareProps {
	callObject: DailyCall | null;
}

const ScreenShare: React.FC<IScreenShareProps> = ({ callObject }) => {
	const classes = useStyles();
	const [callState, dispatch] = useReducer(callReducer, initialCallState);

	//Start listening for participant changes, when the callObject is set.
	useEffect(() => {
		if (!callObject) return;

		const events: DailyEvent[] = [
			'participant-joined',
			'participant-updated',
			'participant-left'
		];

		const handleNewParticipantsState = (): void => {
			dispatch({
				type: PARTICIPANTS_CHANGE,
				callObject: callObject
			});
		};

		// Use initial state
		handleNewParticipantsState();

		// Listen for changes in state
		for (const event of events) {
			callObject.on(event, handleNewParticipantsState);
		}

		// Stop listening for changes in state
		return function cleanup() {
			for (const event of events) {
				callObject.off(event, handleNewParticipantsState);
			}
		};
	}, [callObject]);

	//Start listening for fatal errors, when the callObject is set.
	useEffect(() => {
		if (!callObject) return;

		function handleErrorEvent(e: any) {
			dispatch({
				type: FATAL_ERROR,
				message: (e && e.errorMsg) || 'Unknown'
			});
		}

		callObject.on('error', handleErrorEvent);

		return function cleanup() {
			callObject.off('error', handleErrorEvent);
		};
	}, [callObject]);

	return (
		<Grid container className={classes.root}>
			{callObject !== null &&
				callState.callList
					.filter((callItem: any) => isScreenSharing(callItem.id))
					.map((callItem: any) => {
						return (
							<Fragment key={callItem.id}>
								<Grid item className={classes.item}>
									<VideoTile
										isLocalPerson={isLocal(callItem.id)}
										videoTrackState={
											callItem.videoTrackState as DailyTrackState
										}
										audioTrackState={
											callItem.audioTrackState as DailyTrackState
										}
									/>
								</Grid>
							</Fragment>
						);
					})}
		</Grid>
	);
};

export default ScreenShare;
