import React, { Fragment, useState } from 'react';

import { useDataState } from '../../contexts/DataProvider';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';

import DeleteIcon from '@material-ui/icons/Delete';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import ArchiveIcon from '@material-ui/icons/Archive';
import EditIcon from '@material-ui/icons/Edit';

import Autocomplete from '@material-ui/lab/Autocomplete';

import Confirmation from '../../components/Confirmation/Confirmation';
import ArchiveConfirmation from '../Confirmation/ArchiveConfirmation';

import { ICompany } from '../../interfaces/company';

import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { RemoveType } from '../../types/types';

interface ICompanyHeaderProps {
	/** The current selected company */
	selectedCompany: ICompany | null;

	/** The value in the company auto complete */
	companyInputValue: string;

	/** When the input on company auto complete is changes */
	onInputChange: (newInput: string) => void;

	/** When the company changes on auto complete */
	onCompanyChange: (newCompany: ICompany | null) => void;

	/** When user showing the company details (add or edit) */
	onShowCompanyDetails: (editMode: boolean) => void;

	/** Make request to get companies */
	onGetCompanies: (includeArchvive: boolean) => void;

	/** When the company auto complete clears */
	onClearCompanyInput: () => void;

	/** When a request has completed */
	onRequestComplete: (severity: 'success' | 'error', message: string) => void;
}

const useStyles = makeStyles(theme => ({
	title: {
		marginTop: '25px'
	},
	fill: {
		width: '100%'
	},
	companyPaper: {
		marginTop: '20px',
		paddingBottom: '100px'
	},
	flexCenter: {
		display: 'flex',
		justifyContent: 'center'
	}
}));

const CompanyHeader: React.FC<ICompanyHeaderProps> = ({
	selectedCompany,
	companyInputValue,
	onInputChange,
	onCompanyChange,
	onShowCompanyDetails,
	onGetCompanies,
	onClearCompanyInput,
	onRequestComplete
}) => {
	const classes = useStyles();

	const { data, setData } = useDataState();

	const [archiveChecked, setArchiveChecked] = useState<boolean>(false);
	const [showUnarchive, setShowUnarchive] = useState<boolean>(false);
	const [showDelete, setShowDelete] = useState<boolean>(false);
	const [showRemove, setShowRemove] = useState<boolean>(false);

	/**
	 * When the user toggles the show archived companies checkbox
	 * @param {React.ChangeEvent<HTMLInputElement>} event - The event of the check box
	 */
	const handleArchiveChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const checked = event.target.checked;
		setArchiveChecked(checked);

		onGetCompanies(checked);

		if (!checked) {
			if (selectedCompany?.isArchived) {
				onClearCompanyInput();
			}
		}
	};

	/**
	 * Makes a request to either set company as archived or delete company (PUT/DELETE)
	 */
	const confirmRemove = (removeType: RemoveType): void => {
		if (selectedCompany !== null) {
			if (removeType === 'archive') {
				const archivedCompany: ICompany = {
					isArchived: true
				};

				axios
					.put(`/api/company/${selectedCompany.id}`, JSON.stringify(archivedCompany), {
						headers: { 'Content-Type': 'application/json' }
					})
					.then(response => {
						// remove from list if archive companies are not showing
						if (!archiveChecked) {
							const newCompanyList = data.companies!.filter(
								company => company.id !== selectedCompany.id
							);
							setData(prevData => ({ ...prevData, companies: newCompanyList }));
							onClearCompanyInput();
							setShowRemove(false);
							onRequestComplete('success', 'Company archived.');
						}
						setShowRemove(false);
					})
					.catch(err => {
						onRequestComplete('error', 'Failed to archive company, please try again.');
					});
			} else {
				axios
					.delete(`/api/company/${selectedCompany.id}`)
					.then(response => {
						const newCompanyList = data.companies!.filter(
							company => company.id !== selectedCompany.id
						);
						setData(prevData => ({ ...prevData, companies: newCompanyList }));
						onClearCompanyInput();
						setShowRemove(false);
						onRequestComplete('success', 'Company deleted.');
					})
					.catch(err => {
						onRequestComplete('error', 'Failed to delete company, please try again.');
					});
			}
		}
	};

	/**
	 * Makes a request to set company as un archived (PUT)
	 */
	const handleUnarchiveCompany = (): void => {
		if (selectedCompany !== null) {
			const archivedCompany: ICompany = {
				isArchived: false
			};

			axios
				.put(`/api/company/${selectedCompany.id}`, JSON.stringify(archivedCompany), {
					headers: { 'Content-Type': 'application/json' }
				})
				.then(response => {
					const newCompanyList = [...data.companies!];
					const updatedCompany: ICompany = response.data;
					const oldCompanyIndex = data.companies!.findIndex(
						company => company.id === selectedCompany.id
					);
					newCompanyList[oldCompanyIndex] = updatedCompany;
					setData(prevData => ({ ...prevData, companies: newCompanyList }));
					onCompanyChange(updatedCompany);
					setShowUnarchive(false);
					onRequestComplete('success', 'Company un archived.');
				})
				.catch(err => {
					onRequestComplete('error', 'Failed to archive company, please try again.');
				});
		}
	};

	/**
	 * Makes a request to delete company (DELETE)
	 */
	const handleDeleteCompany = (): void => {
		if (selectedCompany) {
			axios
				.delete(`/api/company/${selectedCompany.id}`)
				.then(response => {
					const newCompanyList = data.companies!.filter(
						company => company.id !== selectedCompany.id
					);
					setData(prevData => ({ ...prevData, companies: newCompanyList }));
					onClearCompanyInput();
					setShowDelete(false);
					onRequestComplete('success', 'Company deleted.');
				})
				.catch(err => {
					onRequestComplete('error', 'Failed to delete company, please try again.');
				});
		}
	};

	return (
		<Fragment>
			<Paper className={classes.companyPaper}>
				<Grid container justify="center" spacing={5}>
					<Grid item className={classes.title}>
						<Typography variant="h3">Manage Company</Typography>
						<hr />
					</Grid>
					<Grid container justify="center" alignItems="center">
						<form
							autoComplete="off"
							style={{
								width: '60vw',
								display: 'flex',
								flexDirection: 'row'
							}}
						>
							<Grid container justify="center" alignItems="center">
								<Grid item xs={2} className={classes.flexCenter}>
									<Button
										variant="contained"
										disableTouchRipple
										onClick={() => onShowCompanyDetails(false)}
									>
										Create Profile
									</Button>
								</Grid>

								<Grid item xs={8}>
									<Autocomplete
										options={data.companies ? data.companies : []}
										getOptionLabel={option => (option.name ? option.name : '')}
										renderInput={params => (
											<TextField
												{...params}
												label="Company"
												variant="outlined"
											/>
										)}
										onChange={(event: any, newValue: ICompany | null) =>
											onCompanyChange(newValue)
										}
										inputValue={companyInputValue}
										value={selectedCompany as ICompany}
										onInputChange={(event, newInputValue) => {
											onInputChange(newInputValue);
										}}
									/>
								</Grid>
								<Grid item xs={2} className={classes.flexCenter}>
									{selectedCompany?.isArchived ? (
										<Fragment>
											<Tooltip title="Unarchive Company">
												<IconButton
													color="primary"
													onClick={() => setShowUnarchive(true)}
												>
													<UnarchiveIcon />
												</IconButton>
											</Tooltip>
											<Tooltip title="Delete Company">
												<IconButton
													color="primary"
													onClick={() => setShowDelete(true)}
												>
													<DeleteIcon />
												</IconButton>
											</Tooltip>
										</Fragment>
									) : (
										<Fragment>
											<Tooltip title="Edit Company">
												<span>
													<IconButton
														color="primary"
														onClick={() => onShowCompanyDetails(true)}
														disabled={selectedCompany === null}
													>
														<EditIcon />
													</IconButton>
												</span>
											</Tooltip>
											<Tooltip title="Archive Company">
												<span>
													<IconButton
														color="primary"
														onClick={() => setShowRemove(true)}
														disabled={selectedCompany === null}
													>
														<ArchiveIcon />
													</IconButton>
												</span>
											</Tooltip>
										</Fragment>
									)}
								</Grid>
							</Grid>
						</form>
					</Grid>

					<Grid container justify="center">
						<Grid item>
							<FormControlLabel
								control={
									<Checkbox
										checked={archiveChecked}
										onChange={handleArchiveChange}
										color="primary"
									/>
								}
								label="Show Archived Companies"
							/>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
			<ArchiveConfirmation
				showConfirmation={showRemove}
				onCloseDialog={() => setShowRemove(false)}
				title="Remove Company"
				message="You have selected to remove a company, do you want to archive it or delete completely?"
				onConfirmRemove={confirmRemove}
			/>
			<Confirmation
				visiblity={showUnarchive}
				title="Un Archive this Company?"
				content="Are you sure you want to un archive this company?"
				onConfirm={handleUnarchiveCompany}
				onCancel={() => setShowUnarchive(false)}
			/>
			<Confirmation
				visiblity={showDelete}
				title="Delete this Company?"
				content="Are you sure you want to delete this company? This will remove all company info from the database"
				onConfirm={handleDeleteCompany}
				onCancel={() => setShowDelete(false)}
			/>
		</Fragment>
	);
};

export default CompanyHeader;
