import { Fragment, useState, useEffect } from 'react';
import { List, ListItem, Typography } from '@material-ui/core';
import ErrorDialog from './ErrorDialog';
import DailyIframe from '@daily-co/daily-js';

const BrowserErrorHandling = () => {
	const [error, setError] = useState<boolean>(false);

	useEffect(() => {
		const supportedBrowser = DailyIframe.supportedBrowser().supported;

		!supportedBrowser && setError(true);
	}, [setError]);

	return (
		<ErrorDialog
			content={
				<Fragment>
					<Typography>
						You'll need to <b>upgrade your browser</b> before joining the experience!
						<br />
						Please download one of the following. Access your sign-in link from your new
						browser once it's downloaded.
					</Typography>
					<List>
						<ListItem disableGutters>
							•
							<a href="https://www.google.ca/chrome/?brand=FHFK&gclsrc=ds&gclsrc=ds">
								Google Chrome
							</a>
						</ListItem>
						<ListItem disableGutters>
							•<a href="https://www.microsoft.com/en-us/edge">Microsoft Edge</a>
						</ListItem>
						<ListItem disableGutters>
							•<a href="https://www.mozilla.org/en-CA/firefox/new/">Firefox</a>
						</ListItem>
						<ListItem disableGutters>
							•
							<a href="https://support.apple.com/downloads/safari">
								Safari (for Mac)
							</a>
						</ListItem>
					</List>
				</Fragment>
			}
			visibility={error}
		/>
	);
};

export default BrowserErrorHandling;
