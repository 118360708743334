import React, { Fragment, useState, useEffect, useReducer } from 'react';
import { DailyTrackState } from '@daily-co/daily-js';
import { useSocketManager } from '../contexts/SocketManager';
import Grid from '@material-ui/core/Grid';

import { initialCallState, callReducer } from '../utils/callState';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

import FacilitatorRoomManager from '../components/RoomSystems/Facilitator/FacilitatorRoomManager';
import StaffNavigation from '../components/StaffNavigation';
import PreviewDocument from '../components/PreviewDocument';
import AudioTrack from '../components/Video/AudioTrack';

import { IDocument } from '../interfaces/document';
import { IAsset } from '../interfaces/asset';

import { matchImage, matchVideo } from '../utils/fileMatch';

import { makeStyles } from '@material-ui/core/styles';
import { IExperienceRoomData } from '../interfaces/roomSocketData';
import { useRef } from 'react';

const useStyles = makeStyles(theme => ({
	loadingContainer: {
		height: '80vh'
	},
	previewDocument: {
		position: 'absolute',
		top: 0,
		left: 0
	}
}));

const FacilitatorView: React.FC = () => {
	const classes = useStyles();
	const { socket, rooms, connect, facilitatorData, leaveRooms, callObject } = useSocketManager();

	const [callState] = useReducer(callReducer, initialCallState);
	const [previewDocument, setPreivewDocument] = useState<IDocument | null>(null);

	const headerRef = useRef<HTMLDivElement | null>(null);
	let headerHeight: number;

	if (headerRef.current !== null) {
		headerHeight = headerRef.current.clientHeight;
	}

	useEffect(() => {
		if (!socket) {
			connect();
		}
	}, [socket, connect]);

	useEffect(() => {
		return () => {
			leaveRooms('Experience Room');
		};
	}, [leaveRooms]);

	/**
	 * When facilitators want to preview assets
	 * @param {IAsset} asset - Asset to preview
	 */
	const handlePreviewAsset = (asset: IAsset) => {
		const isImage = matchImage(asset.url);
		const isVideo = matchVideo(asset.url);

		const newDocument: IDocument = {
			name: asset.key,
			url: asset.url,
			type: isImage ? 'image' : isVideo ? 'video' : 'notSupported',
			posX: 0,
			posY: 0,
			width: 0,
			height: 0
		};

		setPreivewDocument(newDocument);
	};

	/**
	 * Removes the current document in preview
	 */
	const handleRemovePreviewDocument = () => {
		setPreivewDocument(null);
	};

	return (
		<Fragment>
			<AppBar position="static" ref={headerRef}>
				<Toolbar>
					<StaffNavigation />
					<Typography variant="h6">
						{facilitatorData?.company?.name ? `${facilitatorData.company?.name}` : ''}
						{facilitatorData?.eventName ? ` - ${facilitatorData.eventName}` : ''}
					</Typography>
				</Toolbar>
			</AppBar>
			<Grid container style={{ flexWrap: 'nowrap' }}>
				<Grid item xs={12}>
					{rooms.experienceRooms.length > 0 && rooms.announcementRoom ? (
						<Grid container style={{ flexWrap: 'nowrap' }}>
							{rooms.experienceRooms.map((room: IExperienceRoomData) => (
								<Grid item key={room.space}>
									<FacilitatorRoomManager
										socket={socket}
										space={room.space}
										roomData={room}
										announcementRoomData={rooms.announcementRoom!}
										onPreviewAsset={handlePreviewAsset}
										announcementRoomHeaderHeight={headerHeight}
									/>
								</Grid>
							))}
						</Grid>
					) : (
						<Grid
							container
							justify="center"
							alignItems="center"
							className={classes.loadingContainer}
						>
							<Grid item>
								<Typography variant="h2">Joining Rooms</Typography>
								<LinearProgress />
							</Grid>
						</Grid>
					)}
				</Grid>
			</Grid>
			{callObject && (
				<div>
					{Object.entries(callState.callItems).map(([id, callItem], index: number) => (
						<AudioTrack
							key={id}
							audioTrackState={(callItem as any).audioTrackState as DailyTrackState}
						/>
					))}
				</div>
			)}
			<div className={classes.previewDocument}>
				{previewDocument && (
					<PreviewDocument
						document={previewDocument}
						onRemoveDocument={handleRemovePreviewDocument}
					/>
				)}
			</div>
		</Fragment>
	);
};

export default FacilitatorView;
