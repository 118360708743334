import React, { useRef } from 'react';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';

import { ResizableBox } from 'react-resizable';

import { IDocument } from '../interfaces/document';

import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';
import { Container } from '@material-ui/core';
import useWindowDimensions from '../utils/useWindowDimensions';

interface IDocumentProps {
	document: IDocument;
	onRemoveDocument: () => void;
	// set dimensions of free space where assets should be previewed
	desktopHeight?: number;
	desktopWidth?: number;
}

const useStyles = makeStyles(theme => ({
	documentWrapper: {
		position: 'absolute',
		width: 'auto',
		objectFit: 'contain'
	},
	document: {
		backgroundColor: '#ccc',
		border: '1px solid black',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 'auto !important',
		padding: theme.spacing(2)
	},
	resizableDocument: {
		objectFit: 'contain',
		height: 'auto',
		width: 'auto',
		display: 'flex',
		justifyContent: 'center'
	},
	documentTitle: {
		fontSize: '14px',
		textAlign: 'center'
	},
	documentContent: {
		width: 'auto'
	},
	closeButton: {
		margin: theme.spacing(0.5),
		position: 'absolute',
		top: -4,
		right: -4,
		zIndex: 1
	},
	imageContent: {
		height: '100%',
		width: '100%',
		pointerEvents: 'none'
	},
	gridCenter: {
		display: 'flex',
		justifyContent: 'center'
	},
	'@global': {
		video: window.location.href.match('/facilitator-view')
			? {
					maxHeight: 400,
					[theme.breakpoints.up('lg')]: {
						maxHeight: 700
					}
			  }
			: {
					maxHeight: 450,
					[theme.breakpoints.up('md')]: {
						maxHeight: 600
					},
					[theme.breakpoints.up('lg')]: {
						maxHeight: 850
					}
			  }
	}
}));

const PreviewDocument: React.FC<IDocumentProps> = ({
	document,
	onRemoveDocument,
	desktopWidth,
	desktopHeight
}) => {
	const classes = useStyles();
	const { height } = useWindowDimensions();
	const playerRef = useRef<any>();

	const assetTitle =
		document.type === 'video'
			? document.name.replace('.mp4', '')
			: document.name.replace('.jpg', '');

	return (
		<Container
			style={
				!window.location.href.match('/facilitator-view')
					? {
							width: desktopWidth ? desktopWidth : '75vw',
							maxWidth: '75vw',
							height: desktopHeight ? desktopHeight : '75vh',
							maxHeight: '75vh'
					  }
					: {
							position: 'absolute',
							top: '25vh',
							width: '100vw',
							height: '70vh'
					  }
			}
		>
			<div className={classes.resizableDocument}>
				<div className={classes.documentWrapper}>
					<ResizableBox
						className={classes.document}
						height={500}
						width={400}
						resizeHandles={['se']}
						handleSize={[8, 8]}
						minConstraints={[300, 500]}
						maxConstraints={
							document.type === 'video' ? [800, 600] : [500, height - 400]
						}
						draggableOpts={{ grid: [10, 10] }}
						lockAspectRatio
					>
						<Grid container className={classes.gridCenter}>
							<Grid item xs={12}>
								<IconButton
									className={classes.closeButton}
									size="small"
									color="primary"
									onClick={() => onRemoveDocument()}
								>
									<CloseIcon fontSize="inherit" />
								</IconButton>
								<p className={classes.documentTitle}>{assetTitle}</p>
							</Grid>
							<Grid item xs={12}>
								<div className={classes.documentContent}>
									{document.type === 'image' && (
										<img
											className={classes.imageContent}
											src={document.url}
											alt={document.name}
										/>
									)}
									{document.type === 'video' && (
										<ReactPlayer
											ref={playerRef}
											url={document.url}
											width="100% !important"
											height="auto !important"
											controls
											config={{
												file: {
													attributes: {
														disablePictureInPicture: true,
														controlsList: 'nodownload'
													}
												}
											}}
										/>
									)}
									{document.type === 'notSupported' && (
										<p>This Asset's format is not supported</p>
									)}
								</div>
							</Grid>
						</Grid>
					</ResizableBox>
				</div>
			</div>
		</Container>
	);
};

export default PreviewDocument;
