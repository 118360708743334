import React, { useRef, SyntheticEvent } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { ResizableBox, ResizeCallbackData } from 'react-resizable';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';

import { IDocument } from '../../interfaces/document';

import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';
import { Container, Typography } from '@material-ui/core';

interface IDocumentProps {
	desktopWidth: number;

	desktopHeight: number;

	document: IDocument;

	onRemoveDocument: (id: string) => void;

	onUpdateDocument: (document: IDocument) => void;

	onDocumentSelected: (document: IDocument) => void;
}

const useStyles = makeStyles(theme => ({
	documentWrapper: {
		position: 'absolute',
		width: 'auto',
		objectFit: 'contain'
	},
	document: {
		backgroundColor: '#ccc',
		border: '1px solid black',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 'auto !important'
	},
	video: {
		backgroundColor: '#ccc',
		border: '1px solid black',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		margin: theme.spacing(6, 0),
		width: '100%',
		height: 'auto !important'
	},
	documentTitle: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		marginBottom: theme.spacing(1)
	},
	documentContent: {
		padding: theme.spacing(2)
	},

	closeButton: {
		position: 'absolute',
		right: 0,
		zIndex: 3,
		marginRight: theme.spacing(2)
	},
	imageContent: {
		cursor: 'auto'
	},
	gridCenter: {
		display: 'flex',
		justifyContent: 'center'
	},
	draggableDocument: {
		objectFit: 'contain',
		height: 'auto',
		width: 'auto'
	},
	sw: {
		bottom: 5,
		left: 5,
		transform: 'rotate(90deg)',
		cursor: 'sw-resize'
	},
	se: {
		bottom: 5,
		right: 5,
		cursor: 'se-resize'
	},
	'@global': {
		'.dragHandle': {
			'&:hover': {
				cursor: 'grab'
			},
			'&:active': {
				cursor: 'grabbing'
			}
		},

		video: {
			maxHeight: 500,
			[theme.breakpoints.up('md')]: {
				maxHeight: 575
			},
			[theme.breakpoints.up('lg')]: {
				maxHeight: 900
			}
		}
	}
}));

const Document: React.FC<IDocumentProps> = ({
	desktopWidth,
	desktopHeight,
	document,
	onUpdateDocument,
	onRemoveDocument,
	onDocumentSelected
}) => {
	const classes = useStyles();
	const playerRef = useRef<any>();

	// Move and resize handling
	const handleMove = (e: DraggableEvent, data: DraggableData) => {
		document.posX = data.x;
		document.posY = data.y;

		onUpdateDocument(document);
	};

	const handleResize = (e: SyntheticEvent, data: ResizeCallbackData) => {
		document.height = data.size.height;
		document.width = data.size.width;

		onUpdateDocument(document);
	};

	const assetTitle = document.name.replace(/\.[^.]*$/, '');

	const resizeHandlePosition = document.posX <= 0 ? 'se' : 'sw';

	const ResizeHandle = ({ ...restProps }: any) => {
		return <div {...restProps} />;
	};

	return (
		<Draggable
			defaultPosition={{ x: document.posX, y: document.posY }}
			defaultClassName={classes.draggableDocument}
			handle=".dragHandle"
			bounds="parent"
			onDrag={handleMove}
		>
			<div
				className={classes.documentWrapper}
				style={resizeHandlePosition === 'sw' ? { right: '25vw' } : { left: '25vw' }}
			>
				<ResizableBox
					className={classes.document}
					height={document.height}
					width={document.width + 100}
					resizeHandles={[resizeHandlePosition]}
					handle={() => (
						<ResizeHandle
							className={`react-resizable-handle ${
								resizeHandlePosition === 'sw' ? classes.sw : classes.se
							}`}
						/>
					)}
					handleSize={[8, 8]}
					minConstraints={[500, 300]}
					maxConstraints={
						document.type === 'video'
							? [desktopWidth / 1.5, desktopHeight]
							: [desktopWidth / 3.12, desktopHeight]
					}
					onResizeStop={handleResize}
					draggableOpts={{ grid: [1, 1], bounds: 'parent' }}
					lockAspectRatio
				>
					<Container className={`${classes.documentContent} dragHandle`}>
						<Grid container className={classes.gridCenter}>
							<Grid item xs={12} className={classes.documentTitle}>
								<Typography variant="subtitle1" color="textPrimary">
									{assetTitle}
								</Typography>

								<IconButton
									className={classes.closeButton}
									size="small"
									color="primary"
									onClick={() => onRemoveDocument(document.name)}
								>
									<CloseIcon fontSize="inherit" />
								</IconButton>
							</Grid>

							<Grid item xs={12} onMouseDown={() => onDocumentSelected(document)}>
								<div>
									{document.type === 'image' && (
										<img
											className={classes.imageContent}
											src={document.url}
											alt={document.name}
											style={{
												maxHeight: desktopHeight - 100,
												width: '100%',
												height: 'auto'
											}}
										/>
									)}
									{document.type === 'video' && (
										<ReactPlayer
											playing
											controls
											config={{
												file: {
													attributes: {
														disablePictureInPicture: true,
														controlsList: 'nodownload'
													}
												}
											}}
											ref={playerRef}
											url={document.url}
											height="auto !important"
											width="100% !important"
										/>
									)}
									{document.type === 'notSupported' && (
										<p>This Asset's format is not supported</p>
									)}
								</div>
							</Grid>
						</Grid>
					</Container>
				</ResizableBox>
			</div>
		</Draggable>
	);
};

export default Document;
