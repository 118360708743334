import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useGlobalState } from '../contexts/GlobalStateProvider';
import { useDataState } from '../contexts/DataProvider';
import { useSocketManager } from '../contexts/SocketManager';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { makeStyles } from '@material-ui/core/styles';

import TabPanel from '../components/TabPanel';

import ManageCompany from './tabViews/ManageCompany';
import ManageEvents from './tabViews/ManageEvents';
import ManageTeam from './tabViews/ManageTeam';
import EventNavigation from './tabViews/EventNavigation';
// import ManageReports from './tabViews/ManageReports';
// import ManageProduct from './tabViews/ManageProduct';

import axios from 'axios';
import { ICompany } from '../interfaces/company';
import { IStaff } from '../interfaces/staff';

const useStyles = makeStyles(theme => ({
	title: {
		backgroundColor: theme.palette.primary.main
	},
	'@global': {
		'.MuiTab-textColorInherit': {
			opacity: 1
		}
	}
}));

const Dashboard: React.FC = () => {
	const { state, setState, setAcknowledgement, closeAcknowledgement } = useGlobalState();
	const { setData } = useDataState();
	const classes = useStyles();
	const history = useHistory();
	const { connect, setFacilitatorData } = useSocketManager();

	const [tabValue, setTabValue] = useState<number>(state.user?.role === 'admin' ? 0 : 3);

	const [staffList, setStaffList] = useState<IStaff[]>([]);

	/**
	 * Makes request to get all companies including archived ones (GET)
	 */
	const getAllCompanies = useCallback(() => {
		axios
			.get('/api/company?type=all', {
				withCredentials: true
			})
			.then(response => {
				const companies: ICompany[] = [];
				response.data.forEach((company: ICompany) => {
					companies.push(company);
				});

				setData(prevData => ({ ...prevData, companies: companies }));
			})
			.catch(err => {
				setAcknowledgement(
					'Error',
					'We were unable to retreive company data, please try again or contact support.',
					'Retry',
					() => {
						getAllCompanies();
						closeAcknowledgement();
					}
				);
			});
	}, [setData, setAcknowledgement, closeAcknowledgement]);

	/**
	 * Makes request to get all companies that are NOT archived (GET)
	 */
	const getUnarchiveCompanies = useCallback(() => {
		axios
			.get('/api/company', {})
			.then(response => {
				const companies: ICompany[] = [];

				response.data.forEach((company: ICompany) => {
					companies.push(company);
				});

				setData(prevData => ({ ...prevData, companies: companies }));
			})
			.catch(err => {
				console.log(err);
				setAcknowledgement(
					'Error',
					'We were unable to retreive company data, please try again or contact support.',
					'Retry',
					() => {
						getUnarchiveCompanies();
						closeAcknowledgement();
					}
				);
			});
	}, [setData, setAcknowledgement, closeAcknowledgement]);

	// Retreive company and staff data
	useEffect(() => {
		if (state.user?.role === 'admin') {
			axios
				.get('/api/staff', {
					withCredentials: true
				})
				.then(response => {
					const staffMemebers: IStaff[] = [];
					response.data.forEach((member: IStaff) => {
						staffMemebers.push(member);
					});
					setData(prevData => ({
						...prevData,
						staff: staffMemebers
					}));
					setStaffList(staffMemebers);
				})
				.catch(err => {
					// Staff memeber should not see these, only admin
				});
		}

		getUnarchiveCompanies();
	}, [state, getUnarchiveCompanies, setData]);

	/**
	 * Handles the change of tab views in this component
	 * @param {React.ChangeEvent<{}>} event - The default change event that gets passed from component
	 * @param {number} newValue - The new value for the tab component to change to
	 */
	const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
		setTabValue(newValue);
	};

	/**
	 * Handles the logout of an account by removing the session
	 */
	const handleLogout = useCallback(() => {
		axios
			.post('/api/logout')
			.then(res => {
				// set Facilitator Data to empty obj on sign out
				// to disconnect from previous event a facilitator might've joined.
				setFacilitatorData(() => ({}));
				setState(() => ({ user: undefined }));
				history.push('/');
			})
			.catch(err => {
				setAcknowledgement(
					'Logout Failed',
					'We were unable to log you out, please try again or contact support.',
					'Ok',
					closeAcknowledgement
				);
			});
	}, [history, setState, setAcknowledgement, closeAcknowledgement, setFacilitatorData]);

	/**
	 * Updates the staff list when it is modified
	 */
	const handleStaffChange = (updatedStaff: IStaff[]): void => {
		setStaffList(updatedStaff);
	};

	/**
	 * Decideds which type of request to make to get the companys
	 * @param includeArchive - to include archive companies in the request or not
	 */
	const handleGetCompanies = (includeArchive: boolean): void => {
		if (includeArchive) {
			getAllCompanies();
		} else {
			getUnarchiveCompanies();
		}
	};

	// If a specific tab page selected, change to that
	useEffect(() => {
		if (history.location.state) {
			const tab = (history.location.state as any).page;

			if (tab) {
				if (tab === -1) {
					handleLogout();
				} else {
					setTabValue(tab);
				}
			}
		}
	}, [handleLogout, history.location.state]);

	useEffect(() => {
		connect();
	}, [connect]);

	return (
		<div>
			<AppBar position="static" className={classes.title}>
				<Toolbar>
					<Grid container justify="center" alignItems="flex-end">
						<Grid item xs={2}>
							<Grid container alignItems="center">
								<Grid item>
									<Typography variant="h5">Dashboard</Typography>
								</Grid>
								<Grid item>
									<Tooltip title="Logout">
										<IconButton onClick={handleLogout} color="secondary">
											<ExitToAppIcon />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={10}>
							{state.user?.role === 'admin' && (
								<Tabs
									value={tabValue}
									onChange={handleTabChange}
									indicatorColor="secondary"
									variant="fullWidth"
								>
									<Tab label="Manage Company" disableTouchRipple />
									<Tab label="Manage Event" disableTouchRipple />
									<Tab label="Manage Team" disableTouchRipple />
									<Tab label="Event Navigation" disableTouchRipple />
									{/* <Tab label="Manage Reports" disableTouchRipple />
									<Tab label="Manage Product" disableTouchRipple /> */}
								</Tabs>
							)}
							{state.user?.role === 'facilitator' && (
								<Tabs
									value={tabValue}
									onChange={handleTabChange}
									indicatorColor="secondary"
									textColor="inherit"
								>
									<Tab label="Event Navigation" value={3} disableTouchRipple />
								</Tabs>
							)}
							{state.user?.role === 'coach' && (
								<Tabs
									value={tabValue}
									onChange={handleTabChange}
									indicatorColor="secondary"
									textColor="inherit"
								>
									<Tab label="Event Navigation" value={3} disableTouchRipple />
								</Tabs>
							)}
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<TabPanel value={tabValue} index={0}>
				<ManageCompany onGetCompanies={handleGetCompanies} />
			</TabPanel>
			<TabPanel value={tabValue} index={1}>
				<ManageEvents />
			</TabPanel>
			<TabPanel value={tabValue} index={2}>
				<ManageTeam staff={staffList} onStaffChanged={handleStaffChange} />
			</TabPanel>
			<TabPanel value={tabValue} index={3}>
				<EventNavigation />
			</TabPanel>
			{/* <TabPanel value={tabValue} index={4}>
				<ManageReports />
			</TabPanel>
			<TabPanel value={tabValue} index={5}>
				<ManageProduct />
			</TabPanel> */}
		</div>
	);
};

export default Dashboard;
